import React from 'react';
import { FeatureContainer, FeatureButton } from './FeatureElements';

const Feature = () => {
  return (
    <FeatureContainer>
      <h1>Traditional Turkish Foods</h1>
      <p>You can order for your meetings, parties or daily meals. <br /> We make your parties delicious with our homemade products.</p>
      
    </FeatureContainer>
  );
};

export default Feature;

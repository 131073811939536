import React from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink
} from './FooterElements';
import Logo from '../../images/logo.jpeg'

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/'><img src={Logo} width="200" /></SocialLogo>
            <SocialIconLink href='https://www.metadatasolutions.co.uk' target='_blank' aria-label='Metadata-Solutions'>
                Designed and Created by Metadata Solutions
              </SocialIconLink>
          </SocialMediaWrap>    
        </SocialMedia>      
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;

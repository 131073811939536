import React from 'react';
import { Nav, NavLink, NavIcon, Bars } from './NavbarElements';
import Logo from '../../images/logo.jpeg'

const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavLink to='/'><img src={Logo} width="200" />
        </NavLink>
        
      </Nav>
    </>
  );
};

export default Navbar;

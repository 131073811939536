import product1 from '../../images/product-1.jpg';
import product2 from '../../images/product-2.jpg';
import product3 from '../../images/product-3.jpg';
import sweet1 from '../../images/sweet3.jpg';
import sweet2 from '../../images/sweet-2.jpg';
import sweet3 from '../../images/sweet-3.jpg';

export const productData = [
  {
    img: product1,
    alt: 'Yaprak Sarma',
    name: 'Yaprak Sarma',
    desc:
      'vine leaf filled with rice',
    
    
  },
  {
    img: product2,
    alt: 'Icli Kofte',
    name: 'Icli Kofte',
    desc:
      'bulgur wheat stuffed with mince meat',

  },
  {
    img: product3,
    alt: 'manti',
    name: 'Manti',
    desc:
      ' pastry filled with mince meat',

  }
];

export const productDataTwo = [
  {
    img: sweet2,
    alt: 'cone cake',
    name: 'Personalised Birthday Cakes',
    desc:
    ' and for any occasions',
  },
  {
    img: sweet3,
    alt: 'Tiramisu',
    name: 'Tiramisu',
    desc:
    ' homemade and without alcohol',

  },
  {
    img: sweet1,
    alt: 'Cheesecake',
    name: 'Raspberry Cheesecake',
    desc:
    ' and many more homemade cakes and desserts...',
  }
];

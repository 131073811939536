import React, { useState } from 'react'
import './contact.css'
import { useRef } from 'react'
import emailjs from '@emailjs/browser';
import Phone from '../../images/phone.png'
import Email from '../../images/email.png'
import Location from '../../images/location.png'

const Contact = () => {
    const formRef = useRef()
    const [done, setDone] = useState(false)
    
    const handleSubmit = (e)=>{
    e.preventDefault()
    emailjs.sendForm('service_5qefi61', 'template_p3vtbui', formRef.current, 'pYIz2kpl02D5tkdPr')
    .then((result) => {
        console.log(result.text);
        setDone(true)
    }, (error) => {
        console.log(error.text);
    });
}

  return (
    <div className="c">
        <div className="c-bg"></div>
            <div className="c-wrapper">
                <div className="c-left">
                    <h1 className="c-title">
                        Contact
                    </h1>
                    <div className="c-info">
                    <div className="c-info-item">
                        <img src={Phone} alt="" className='c-icon' />
                        +44 7464 792270
                    </div>
                    <div className="c-info-item">
                    <img src={Email} alt="" className='c-icon' />
                        info@catering4organisation.co.uk
                    </div>
                    <div className="c-info-item">
                    <img src={Location} alt="" className='c-icon'/>
                        Coventry
                    </div>
                    </div>
                </div>
                <div className="c-right"> 
                <p className="c-desc"><b>Get in touch.</b> Your requests and suggestions
                 are important to us.
                </p>
                <form ref={formRef} on onSubmit={handleSubmit}>
                    <input type="text" placeholder="Name" name="user_name" />
                    <input type="text" placeholder="Subject" name="user_subject" />
                    <input type="text" placeholder="Email" name="user_email" />
                    <textarea rows="5" placeholder="Message" name="message" />
                    <button>Submit</button>
                    {done && "Your message has been sent. Thank you."}
                </form>
                </div>
            </div>
        
    </div>
  )
}

export default Contact
import React from 'react'
import './about.css'

const about = () => {
  return (
    <div className="a">
        <div className="a-wrapper">
            <div className="a-title"><h1>About us</h1></div>
            <div className="a-info">
                <p>Since 2020 we at Catering4organisation aim to prepare our traditional Turkish food and snacks to a higher standard.</p><br />
                <p>We bake personalised birthday cakes and Turkish foods such as Manti (Turkish Raviouli) Icli kofte (bulgur wheat stuffed mince meat), yaprak sarma (vine leaves stuffed with rice) and Baklava and many more...</p><br />
                <p>We either prepare the requested food on the day or the evening before in order for them to stay fresh on your special day. We purchase all of our ingredients fresh and above average products. Meat products are HMC certified.
We also prepare frozen pastries which make it easier for our customers to cook them whenever.</p>


            </div>
        </div>
    </div>
  )
}

export default about

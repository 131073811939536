import T1 from "../../images/T-1.jpg";
import T2 from "../../images/T-2.jpg";
import T3 from "../../images/T-3.jpg";
import T4 from "../../images/T-4.jpg";
import T5 from "../../images/T-5.jpg";
import T6 from "../../images/T-6.jpg";
import T7 from "../../images/T-7.jpg";


export const testimonialsData = [
    {
        image: T1,
        review:
        "We use all her tasty specialities on all our events, parties and general food requests. Amazing food",
        name: 'Zeynep Turudi',
        status : 'Amazing',

    },
    {
        image: T2,
        review:
        "We agreed with the catering 4 organization for cake and snacks for my daughter's first birthday party. Ms. Sevtap is so professional in this matter that she made the cake of our dreams come true. The snacks she prepared were just as delicious. All the guests attending our party specifically asked where we had the cake made. Everything was wonderful, both visually and in taste. From now on, we will prefer catering 4 organization for all our special meals and birthday organizations.",
        name: 'Alihan Duran',
        status : 'Wonderful',

    },
    {
        image: T3,
        review:
        "Delicious Turkish origin food, I’ve ordered many types to keep in my freezer and later cook when I have last minute guests! Recommend to all my friends xx",
        name: 'Shaz Bilge',
        status : 'Strikely Recommended',

    },
    {
        image: T4,
        review:
        "Most reliable, Hygienic, perfect every order and with most beautiful smule I would recommend Catering4Organisation 100 % and wish them well for the future ",
        name: 'Bedouin Nights Aisha',
        status : 'Delicous and Hygienic',

    },
    {
        image: T5,
        review:
        "I ordered a variety of dishes and loved it so much that i decided  to order more. I keep them in the feezer for last minute guests. My guests loved it. Thank you for the amazing food.",
        name: 'Ayse Guler',
        status : 'Practice and Tasty',

    },
    {
        image: T6,
        review:
        "The best birthday cake I have ever tasted . Freshly made and it looked great . I highly recommend. Thank you",
        name: 'Kamera Studios',
        status : 'Fresh',

    },
    {
        image: T7,
        review:
        "Fantastic service. Well organized and easy to communicate. Food is absolutely gorgeous. Fresh, good quality and rich in flavor. Highly recommended.",
        name: 'Edytaa Avoc',
        status : 'Fantastic',

    },
];